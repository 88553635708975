/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@fontsource/inter"
import "./src/styles/global.css"
import "./src/styles/variables.css"

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.state?.retainPrevScrollPosition) return false
  return true
}
